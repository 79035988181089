// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-artikler-js": () => import("./../src/pages/artikler.js" /* webpackChunkName: "component---src-pages-artikler-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-danmarkskort-js": () => import("./../src/pages/danmarkskort.js" /* webpackChunkName: "component---src-pages-danmarkskort-js" */),
  "component---src-pages-film-1-js": () => import("./../src/pages/film_1.js" /* webpackChunkName: "component---src-pages-film-1-js" */),
  "component---src-pages-film-2-js": () => import("./../src/pages/film_2.js" /* webpackChunkName: "component---src-pages-film-2-js" */),
  "component---src-pages-film-3-js": () => import("./../src/pages/film_3.js" /* webpackChunkName: "component---src-pages-film-3-js" */),
  "component---src-pages-film-4-js": () => import("./../src/pages/film_4.js" /* webpackChunkName: "component---src-pages-film-4-js" */),
  "component---src-pages-film-js": () => import("./../src/pages/film.js" /* webpackChunkName: "component---src-pages-film-js" */),
  "component---src-pages-fur-js": () => import("./../src/pages/fur.js" /* webpackChunkName: "component---src-pages-fur-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-links-js": () => import("./../src/pages/links.js" /* webpackChunkName: "component---src-pages-links-js" */),
  "component---src-pages-opgaver-js": () => import("./../src/pages/opgaver.js" /* webpackChunkName: "component---src-pages-opgaver-js" */),
  "component---src-pages-quiz-2-js": () => import("./../src/pages/quiz_2.js" /* webpackChunkName: "component---src-pages-quiz-2-js" */),
  "component---src-pages-quiz-js": () => import("./../src/pages/quiz.js" /* webpackChunkName: "component---src-pages-quiz-js" */),
  "component---src-pages-skoleavis-js": () => import("./../src/pages/skoleavis.js" /* webpackChunkName: "component---src-pages-skoleavis-js" */),
  "component---src-pages-template-js": () => import("./../src/pages/template.js" /* webpackChunkName: "component---src-pages-template-js" */),
  "component---src-pages-til-laererne-js": () => import("./../src/pages/til-laererne.js" /* webpackChunkName: "component---src-pages-til-laererne-js" */)
}

